import { cn } from "@/libs/utils";

type CheckboxProps = {
	checked?: boolean;
	className?: string;
	id: string;
	label?: string;
	name?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	value: string;
};

const Checkbox: React.FC<CheckboxProps> = ({ id, label, value, onChange, checked, className, ...rest }) => {
	return (
		<div className={cn("flex items-center mr-4 mb-2", className)}>
			<input
				type="checkbox"
				id={id}
				name={label}
				value={value}
				checked={checked}
				className="opacity-0 absolute h-8 w-8"
				onChange={onChange}
				{...rest}
			/>
			<div className="bg-mediumGray rounded-md w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2">
				<svg width="16" height="16Checkbox" viewBox="0 0 16 16" fill="none" className="hidden">
					<path d="M13.3334 4.33331L6.00008 11.6666L2.66675 8.33331" stroke="white" strokeWidth="2" />
				</svg>
			</div>
			<label htmlFor={id} className="select-none cursor-pointer text-sm relative top-[1px] whitespace-nowrap">
				{label}
			</label>
		</div>
	);
};

export default Checkbox;
